import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from 'layouts/full/shared/loadable/Loadable';

/* ****Pages***** */
// Private pages
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const Analytics = Loadable(lazy(() => import('pages/analytics')));
const Balance = Loadable(lazy(() => import('pages/balance')));
const BalanceHistoryDetail = Loadable(lazy(() => import('pages/balance/history/[id]')));
const Transaction = Loadable(lazy(() => import('pages/transaction')));
const TransactionDetail = Loadable(lazy(() => import('pages/transaction/[id]')));
const ChangePassword = Loadable(lazy(() => import('pages/changepassword')));
const Settlement = Loadable(lazy(() => import('pages/settlement')));
const SettlementDetail = Loadable(lazy(() => import('pages/settlement/detail')));
// const Logs = Loadable(lazy(() => import('pages/logs')));
// const LogsDetail = Loadable(lazy(() => import('pages/logs/[id]')));
const PaymentLink = Loadable(lazy(() => import('pages/payment-link')));
const PaymentLinkDetail = Loadable(lazy(() => import('pages/payment-link/[id]')));
const UserDashboard = Loadable(lazy(() => import('pages/user-dashboard')));
const AddMerchant = Loadable(lazy(() => import('pages/user-dashboard/add-merchant')));
const MerchantDetail = Loadable(lazy(() => import('pages/user-dashboard/[id]')));
const CreatePaymentLink = Loadable(lazy(() => import('pages/payment-link/createpaymentlink')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const BusinessProfile = Loadable(lazy(() => import('pages/profile/business-profile')));
const BrandSettings = Loadable(lazy(() => import('pages/profile/brand-settings')));
const RegisterFlow = Loadable(lazy(() => import('pages/FormMerchant/register-flow')));
const RegisterFormEnterprise = Loadable(
  lazy(() => import('pages/FormMerchant/register-form/enterprise')),
);
const RegisterFormPersonal = Loadable(
  lazy(() => import('pages/FormMerchant/register-form/personal')),
);
const Settings = Loadable(lazy(() => import('pages/settings')));
const CreateWebhook = Loadable(lazy(() => import('pages/settings/create-webhook')));
// const InviteNewAdmin = Loadable(lazy(() => import('pages/invite-new-admin')));
const VA = Loadable(lazy(() => import('pages/virtual-account')));
const VADetail = Loadable(lazy(() => import('pages/virtual-account/[id]')));
const MobileVerification = Loadable(
  lazy(() => import('pages/Authentication/mobile-number-verification')),
);
const QrisPayment = Loadable(lazy(() => import('pages/transaction/qris-payment')));
const QrisPaymentDetail = Loadable(lazy(() => import('pages/transaction/qris-payment/[id]')));
const Disbursement = Loadable(lazy(() => import('pages/disbursement')));
const DisbursementDetail = Loadable(lazy(() => import('pages/disbursement/[id]')));

const DepositVA = Loadable(lazy(() => import('pages/depositVA')));
const DepositVADetail = Loadable(lazy(() => import('pages/depositVA/[id]')));

// Public pages
const Register = Loadable(lazy(() => import('pages/Authentication/register')));
const RegisterMember = Loadable(lazy(() => import('pages/Authentication/register-member')));
const Login = Loadable(lazy(() => import('pages/Authentication/login')));
const VerificationEmail = Loadable(lazy(() => import('pages/Authentication/verification-email')));
const VerificationEmailCheck = Loadable(
  lazy(() => import('pages/Authentication/verification-email-check')),
);
const ForgotPassword = Loadable(lazy(() => import('pages/Authentication/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('pages/Authentication/reset-password')));
const ResendEmail = Loadable(lazy(() => import('pages/Authentication/resend-email')));
const FourZeroFour = Loadable(lazy(() => import('pages/z-error/404')));

const authProtectedRoutes = [
  { path: '/', element: <Navigate to="/dashboard" /> },
  { path: '/dashboard', exact: true, element: <Dashboard /> },
  { path: '/analytics', element: <Analytics /> },
  { path: '/balance', element: <Balance /> },
  { path: '/balance/history/:id', element: <BalanceHistoryDetail /> },
  { path: '/changepassword', element: <ChangePassword /> },
  { path: '/transaction', element: <Transaction /> },
  { path: '/transaction/:id', element: <TransactionDetail /> },
  { path: '/settlement', element: <Settlement /> },
  { path: '/settlement/:type', element: <Settlement /> },
  { path: '/settlement/detail', element: <SettlementDetail /> },
  // { path: '/logs', element: <Logs /> },
  // { path: '/logs/:id', element: <LogsDetail /> },
  { path: '/payment-link', element: <PaymentLink /> },
  { path: '/payment-link/:id', element: <PaymentLinkDetail /> },
  { path: '/payment-link/create', element: <CreatePaymentLink /> },
  { path: '/merchant', element: <UserDashboard /> },
  { path: '/merchant/:id', element: <MerchantDetail /> },
  { path: '/add-merchant', element: <AddMerchant /> },
  { path: '/edit-merchant/:id', element: <AddMerchant /> },
  { path: '/profile', element: <Profile /> },
  { path: '/business-profile', element: <BusinessProfile /> },
  { path: '/settings', element: <BrandSettings /> },
  { path: '/api-settings', element: <Settings /> },
  { path: '/api-settings/create-webhook', element: <CreateWebhook /> },
  //   { path: '/invite-new-admin', element: <InviteNewAdmin /> },

  { path: '/virtual-account', element: <VA /> },
  { path: '/virtual-account/:id', element: <VADetail /> },
  { path: '/qris-payment', element: <QrisPayment /> },
  { path: '/qris-payment/:id', element: <QrisPaymentDetail /> },

  { path: '/deposit-va', element: <DepositVA /> },
  { path: '/deposit-va/:id', element: <DepositVADetail /> },

  { path: '/disbursement', element: <Disbursement /> },
  { path: '/disbursement/:id', element: <DisbursementDetail /> },
  { path: '*', element: <FourZeroFour /> },
];

const publicRoutes = [
  { path: '/register', element: <Register /> },
  { path: '/register-member', element: <RegisterMember /> },
  { path: '/login', element: <Login /> },
  { path: '/verification-email/:token', element: <VerificationEmail /> },
  { path: '/verification-email-check', element: <VerificationEmailCheck /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:token', element: <ResetPassword /> },
  { path: '/resend-email/:email', element: <ResendEmail /> },
  { path: '/mobile-verification', element: <MobileVerification /> },
  { path: '/register-flow', element: <RegisterFlow /> },
  { path: '/register-form/enterprise', element: <RegisterFormEnterprise /> },
  { path: '/register-form/personal', element: <RegisterFormPersonal /> },
  { path: '*', element: <FourZeroFour /> },
];

export { authProtectedRoutes, publicRoutes };
